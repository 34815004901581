<template>
  <transition name="fade">
    <section
      v-show="isPlayerLoaded && showBubble"
      :class="[
        'bubble-container',
        isBubbleOnTheRight ? 'right' : 'left',
        bubbleShape,
        bubbleBorder,
      ]"
      @click="clickOnBubble()"
    >
      <div :class="['bubble']">
        <button
          class="close-icon"
          @click.stop="stopCampaign"
          :style="iconPosition"
        >
          <CloseIcon />
        </button>

        <video
          ref="videoPlayer"
          playsinline
          muted
          @canplay="onVideoCanPlay"
          @play="!playBubble && player.pause()"
          @error="onVideoError"
          @timeupdate="hasTrimmedUrl || loopBubble"
          @ended="player.play()"
          :class="['bubble-display', bubbleShape, bubbleBorder]"
          :style="`${bubbleBorderColor}; ${bubbleDimensions}; ${bubbleFitPosition};`"
          :src="placeholderUrl"
        ></video>
        <PlayPause
        class="play-pause-container"
        v-show="!hideThumbnailPlay"

      />
      </div>
      <p
        v-if="title && !isPlayerOpened && hideThumbnailPlay"
        :class="['campaign-text', { right: isBubbleOnTheRight }]"
        :style="{ fontSize: bubbleTextSize }"
      >
        {{ decodeText(title) }}
      </p>
    </section>
  </transition>
</template>

<script>
import CloseIcon from "@/assets/close-small.svg";
import { decodeText, changeIframe } from "@/utils/utils.js";
import enums from "@/enums.js";
const { Position } = enums;

import { mapGetters, mapMutations } from "vuex";

export default {
  components: {
    CloseIcon,
    PlayPause: () =>
      import("../components/player-components/PlayPauseControls.vue"),
  },

  props: {
    videoIndex: {
      type: Number,
      default: 0,
    },

    showBubble: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isPlayerLoaded: false,
      videoOptions: {
        fill: true,
        loadingSpinner: false,
        controls: false,
        muted: true,
        autoplay: true,
        sources: [],
      },
      decodeText,
    };
  },

  watch: {
    playBubble(newVal) {
      newVal && this.player ? this.player.play() : this.player.pause();
    },
  },

  computed: {
    ...mapGetters("playerCampaign", ["format", "isFullScreen"]),
    ...mapGetters("videos", [
      "isMultiple",
      "getVideoByIndex",
      "sizeCoefficient",
      "isPortrait",
      "isLandscape",
    ]),
    ...mapGetters("player", [
      "isMobile",
      "isInPreview",
      "isIos",
      "isPlayerOpened",
    ]),

    ...mapGetters("videoJS", ["placeholderUrl"]),

    player() {
      return this.$refs.videoPlayer;
    },

    bubbleShape() {
      return this.format.bubbleShape;
    },

    playBubble() {
      return !this.isPlayerOpened;
    },

    video() {
      return this.getVideoByIndex(this.videoIndex);
    },

    bubbleTextSize() {
      return `${this.bubbleSize * 0.1}px`;
    },

    bubbleSize() {
      return this.format.bubbleSize ?? 120;
    },

    bubbleBorder() {
      return this.format.bubbleBorder ? "bubble-border" : "no-border";
    },

    bubbleBorderColor() {
      if (this.format.bubbleBorder) {
        return `outline: 5px solid ${this.format.bubbleBorderColor};`;
      } else {
        return "";
      }
    },

    bubbleFitPosition() {
      return `object-position: ${
        this.format.bubbleFitPosition ? this.format.bubbleFitPosition : "center"
      }`;
    },

    title() {
      return this.format.title;
    },

    hasTrimmedUrl() {
      return (
        this.videoOptions.sources.filter((source) => {
          return source.src.includes("compressed-short.mp4");
        }).length > 0
      );
    },

    iconPosition() {
      if (this.bubbleShape === "original") {
        let originalIconSideOffset = this.isPortrait ? -7 : -5;
        let originalIconTopOffset = this.isPortrait ? -5 : -11;
        let iconPosition =
          this.format.position === Position.left ? "right:" : "left:";

        if (this.format.bubbleBorder) originalIconTopOffset -= 2;

        return `
          ${iconPosition} ${originalIconSideOffset}%;
          top: ${originalIconTopOffset}%;
        `;
      }

      const iconOffset = this.format.bubbleBorder ? 6 : 12;
      return this.format.position === Position.left
        ? `left: ${this.bubbleSize - iconOffset}px; bottom: ${
            (this.bubbleSize - 22) / 2
          }px;`
        : `right: ${this.bubbleSize - iconOffset}px; top: ${
            (this.bubbleSize - 22) / 2
          }px;`;
    },

    isBubbleOnTheRight() {
      return this.format.position === Position.right;
    },
    // TODO: remove this when we have a better solution because bubble can take 100% + margins
    calculateSizeOriginal() {
      let height = this.bubbleSize;
      let width = this.bubbleSize;
      if (this.sizeCoefficient < 0.8) {
        height = height / this.sizeCoefficient;
      }
      if (this.sizeCoefficient > 1.2) {
        width = width * this.sizeCoefficient;
      }
      return {
        width,
        height,
      };
    },

    bubbleDimensions() {
      //For the client Time Health, they want the video thumbnail to be bigger
      if (this.$route.params.siteId === 'e537e73f-d665-49aa-9030-d7cc9056a08b' && !this.isMobile) {
        return `width: ${
          this.calculateSizeOriginal.width * 1.4
        }px; height: ${
          this.calculateSizeOriginal.height * 1.4
        }px;`;
      }

      return `width: ${
        this.bubbleShape === "original"
          ? this.calculateSizeOriginal.width
          : this.bubbleSize
      }px; height: ${
        this.bubbleShape === "original"
          ? this.calculateSizeOriginal.height
          : this.bubbleSize
      }px;`;
    },
    hideThumbnailPlay() {
      return this.format.hideThumbnailPlay;
    },
  },

  async mounted() {
    // if the video is trimmed, we want to be placed first in the array
    this.videoOptions.sources = this.getVideoSources();
    this.player.src = this.videoOptions.sources[0].src;
    this.player.play().catch(() => {
      this.isPlayerLoaded = true;
      this.player.pause();
      this.player.currentTime = 0;
      if (this.video.thumbnail) {
        this.player.poster = this.video.thumbnail;
        this.player.src = "";
      } else {
        this.player.src = this.video.url;
        this.player.play();
      }
    });
    // Not sure what this is for why do we need to set full player loaded even though it's only the bubble
    //TO VERIFY
    this.setHasPlayerLoaded(true);
  },

  methods: {
    ...mapMutations({
      togglePlayer: "player/TOGGLE_PLAYER",
      setHasPlayerLoaded: "player/SET_HAS_PLAYER_LOADED",
      toggleMute: "player/TOGGLE_MUTE",
    }),

    getVideoSources() {
      const sourcesArray = [];
      this.video.trimmedUrl &&
        sourcesArray.push({
          src: this.video.trimmedUrl,
          type: "video/mp4",
        });
      this.video.url &&
        sourcesArray.push({
          src: this.video.url,
          type: "video/mp4",
        });
      return sourcesArray;
    },

    onVideoCanPlay() {
      if (!this.isIos && !this.playBubble) {
        this.player.pause();
      }
      this.isPlayerLoaded = true;
    },

    onVideoError() {
      // Get the current video source URL
      const currentSrc = this.player.currentSrc;

      if (currentSrc.includes("compressed-short.mp4")) {
        // Switch to another alternate video source (e.g., m3u8)
        const nonTrimmedUrl = this.videoOptions.sources.find((source) => {
          return !source.src.includes("compressed-short.mp4");
        });

        // Set the new video source and play it
        this.player.src = nonTrimmedUrl.src;
        this.player.play();
      }
    },

    clickOnBubble() {
      this.togglePlayer(!this.isPlayerOpened);
      if (!this.isIos && this.isPlayerOpened) {
        this.toggleMute(!this.isPlayerOpened);
      }
      this.isPlayerOpened && changeIframe("openMainPlayer");
    },

    switchSource() {
      if (this.player.currentSrc().includes("m3u8")) {
        this.player.error(null);
        const mp4Source = this.videoOptions.sources.find((source) => {
          return source.src.includes("compressed.mp4");
        });
        this.player.src([mp4Source]);
      } else if (this.player.currentSrc().includes("compressed-short.mp4")) {
        this.player.error(null);
        const m3u8Source = this.videoOptions.sources.find((source) => {
          return source.src.includes("m3u8");
        });
        this.player.src([m3u8Source]);
      }
    },

    loopBubble() {
      if (this.player?.currentTime || false) {
        if (this.player?.currentTime < 0.1 || this.player.currentTime >= 3.8) {
          this.player.currentTime = 0.1;
        }
      }
    },

    stopCampaign() {
      changeIframe("destroyIframe");
    },
  },
};
</script>

<style lang="scss">
.bubble-display {
  transform: translateZ(0);
  width: 100%;
  height: auto;
  filter: brightness(80%);
  object-fit: cover;
}

.bubble-display.round {
  border-radius: 50%;
}
.bubble-display.original {
  border-radius: 10px;
}
.bubble-display.square {
  border-radius: 4px;
}

.bubble-display.bubble-border {
  margin: 0 6px;
}
</style>

<style lang="scss" scoped>
.bubble-container {
  width: fit-content;
  filter: drop-shadow(2px 5px 2px rgba(0, 0, 0, 0.3));
  transition: opacity 0.5s;
  // position absolute for the bubble to stick at the bottom of the screen
  position: absolute;
  bottom: 8px;
}

.bubble-container.right {
  filter: drop-shadow(-2px 5px 2px rgba(0, 0, 0, 0.3));
}

// for preview
.iframe-wrapper .bubble-container {
  height: auto;
}

.bubble-container.right {
  right: 0px;
  left: unset;
}

.isDesktop .bubble-container.right:hover {
  transform: scale(1.05) translate(-5px, -2px);
}
.bubble-container.left {
  left: 0px;
  right: unset;
}
.isDesktop .bubble-container.left:hover {
  transform: scale(1.05) translate(5px, -2px);
}

.isDesktop .bubble-container.original.left:hover {
  transform: scale(1.05) translate(8px, -5px);
}

.isDesktop .bubble-container.original.right:hover {
  transform: scale(1.05) translate(-8px, -5px);
}

.bubble {
  position: relative;
  cursor: pointer;
}

.campaign-text {
  overflow-wrap: break-word;
  position: absolute;
  pointer-events: none;
  text-align: center;
  color: white;
  font-size: 11px;
  font-weight: bold;
  line-height: 1.3;
  font-family: "Gothic A1", sans-serif;
  transform: translate(-50%, -50%);
  width: 70%;
  left: 50%;
  top: 50%;
}

.close-icon {
  position: absolute;
  padding: 0;
  z-index: $index-icon-position;
  background-color: transparent;
  border-radius: 40px;
  transition: $button-transition;
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
  padding: 4px;
  svg {
    transform: scale(0.9);
    border-radius: 50%;
    fill: black;
  }
}

.isDesktop {
  .close-icon:hover {
    transform: scale(1.1);
    transition: $button-transition;
  }
}


</style>
